import React from "react";
import PageBase from "../pageBase/pageBase";

import "./about.css";

export default class About extends PageBase {
  getPageTitle() {
    return "About Emine Tarin";
  }

  renderPageContent() {
    return (
      <div className="about-us-container">
        <p>
          Welcome to Emine Tarin Limited, your number one source for all things
          clothes. We're dedicated to providing you the very best of clothes and
          seamstress service, with an emphasis on quality, customer
          satisfaction, and an affordable price policy.
        </p>
        <p>
          Founded in 2020 by Emine Tarın, Emine Tarin Limited has come a long
          way from its beginnings in London, United Kingdom. When Emine Tarın
          first started out, her passion for "All dreams are wearable" drove
          them to start their own business.
        </p>

        <p>
          We hope you enjoy our products as much as we enjoy offering them to
          you. If you have any questions or comments, please don't hesitate to
          contact us.
        </p>

        <p>
          Sincerely, <br />
          Emine Tarın
        </p>
      </div>
    );
  }
}
