import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NavBar from "./components/navbar/navbar.jsx";
import Footer from "./components/footer/footer.jsx";
import MainPage from "./pages/mainpage/mainpage.jsx";
import About from "./pages/about/about.jsx";
import Blog from "./pages/blog/blog.jsx";
import Collection from "./pages/collections/collection/collection.jsx";
import Contact from "./pages/contact/contact";
import PaymentOptions from "./pages/paymentOptions/paymentOptions";
import PrivacyPolicy from "./pages/privacyPolicy/privacyPolicy";
import ShippingDelivery from "./pages/shippingDelivery/shippingDelivery";
import TermsAndConditions from "./pages/termsAndConditions/termsAndConditions";
import ReturnPolicy from "./pages/returnPolicy/returnPolicy.jsx";
import ProductOverview from "./pages/product/product-overview";
import Services from "./pages/services/services";
import "./App.css";

export default class App extends React.Component {
  render() {
    return (
      <div className="App">
        <NavBar isMobile="true" />
        <Router>
          <Switch>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/blog">
              <Blog />
            </Route>
            <Route
              path="/store"
              render={() => {
                window.location = "https://www.etsy.com/uk/shop/eminetarinshop";
                return <p>Redirecting</p>;
              }}
            ></Route>
            <Route
              path="/collections/:id"
              render={(props) => <Collection {...props} />}
            ></Route>
            <Route
              path="/products/:id"
              render={(props) => <ProductOverview {...props} />}
            ></Route>
            <Route path="/services">
              <Services />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/payment-options">
              <PaymentOptions />
            </Route>
            <Route path="/privacy-policy">
              <PrivacyPolicy />
            </Route>
            <Route path="/shipping-delivery">
              <ShippingDelivery />
            </Route>
            <Route path="/terms-conditions">
              <TermsAndConditions />
            </Route>
            <Route path="/return-policy">
              <ReturnPolicy />
            </Route>
            <Route path="/">
              <MainPage />
            </Route>
          </Switch>
        </Router>
        <Footer />
      </div>
    );
  }
}

//export default App;
