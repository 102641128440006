import React from "react";
import Carousel from "react-material-ui-carousel";
import CategoryCard from "../../components/categoryCard/categoryCard";
import { Paper, Grid } from "@material-ui/core";
import FullScreenImage from "../../components/fullscreenImage/fullscreenImage";

import "./services.css";
import * as menCustomAlterations from "../../images/services/alexei-maridashvili-4f3VU5uhOEA-unsplash.jpg";
import * as leatherAlterationsServices from "../../images/services/pexels-diego-pontes-2897130.jpg";
import * as womenCustomTailoringServices from "../../images/services/pexels-cottonbro-4622423.jpg";
import * as sewingMachine from "../../images/services/ilya-lix-4l5fsRjJqLM-unsplash.jpg";
import * as bridalsAlterations from "../../images/services/business-1817472_1920.jpg";
import * as seamstressService from "../../images/services/pexels-evelina-zhu-5786977.jpg";
import * as suitAlterations from "../../images/services/pexels-tima-miroshnichenko-6766284.jpg";

export default class Services extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="page-container">
        <h1>Our services</h1>

        <section className="services-container">
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
            spacing={4}
          >
            <Grid item xs={12} sm={4}>
              <CategoryCard
                title="Women's Custom Tailoring Services"
                image={womenCustomTailoringServices}
                // description="asdasdasd"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CategoryCard
                title="Bridal Alterations and Tailoring Services"
                image={bridalsAlterations}
                // description="Aciklama"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CategoryCard
                title="Leather Alterations Services"
                image={leatherAlterationsServices}
                // description="asdasdasd"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CategoryCard
                title="Men's Custom Tailoring Services"
                image={menCustomAlterations}
                // description="asdasdasd"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CategoryCard
                title="Seamstress Services"
                image={seamstressService}
                // description="asdasdasd"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CategoryCard
                title="Suit Alterations and Tailoring Services"
                image={suitAlterations}
                // description="asdasdasd"
              />
            </Grid>
          </Grid>
        </section>
      </div>
    );
  }
}
