import React from "react";
import "./pageCard.css";

export default class PageCard extends React.Component {
  render() {
    return (
      <div className="page-card-container">
        <a className="page-card-url" href={this.props.url}>
          <div className="page-card-wrapper">
            <img
              className="page-card-image img-responsive"
              src={this.props.image}
              alt={this.props.title}
            />
            <div className="page-card-overlay"></div>
            <h1 className="page-card-title">{this.props.title}</h1>
          </div>
        </a>
      </div>
    );
  }
}
