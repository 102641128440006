import React from "react";
import "./blog.css";
import InstagramBlog from "../../components/instagramBlog/instagramBlog";

export default class Blog extends React.Component {
  render() {
    return (
      <div className="page-container">
        <InstagramBlog />
      </div>
    );
  }
}
