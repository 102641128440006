import React from "react";
import PropTypes from "prop-types";
import "./zoomableImage.css";
import * as baseImage from "../../images/productImages/bride-1.jpg";

export default class ZoomableImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageSrc: baseImage,
    };
  }

  async componentDidMount() {
    const { default: currentImage } = await import(
      `../../images/productImages/${this.props.image}`
    );

    this.setState({
      imageSrc: currentImage,
    });
  }

  async getImage() {}
  render() {
    let image;
    if (this.props.image) {
      image = (
        <img
          key={this.props.image}
          src={this.state.imageSrc}
          className="img-responsive"
          alt={this.props.title}
          onMouseEnter={() => {
            this.props.onHoverEvent(true);
          }}
          onMouseLeave={() => {
            this.props.onHoverEvent(false);
          }}
        />
      );
    }

    return (
      <div className="zoomableImage">
        <a href={this.props.link}>{image}</a>
      </div>
    );
  }
}

ZoomableImage.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.string,
  onHoverEvent: PropTypes.func,
};
