import React from "react";
import PageBase from "../pageBase/pageBase";

export default class PaymentOptions extends PageBase {
  getPageTitle() {
    return "Payment Options";
  }

  renderPageContent() {
    return (
      <div>
       As Emine Tarin Limited, we sell our products online only on Etsy in order to provide you with the best service. 
       For this reason, we use Etsy's payment policy. Thus, customers are able to pay by credit card, debit card, Apple Pay, Google Pay, PayPal and Etsy gift coupons. 
       For more detailed information, please go to <a className="basic-link" href="https://www.etsy.com/legal/etsy-payments/">Etsy's payment policies page.</a>
      </div>
    );
  }
}
