import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper, Grid, Divider, Button } from "@material-ui/core";
import FullScreenImage from "../../components/fullscreenImage/fullscreenImage";
import CollectionStore from "../../collections.json";
import * as mainpageCrousel1 from "../../images/main.jpg";
import "./product-overview.css";

export default class ProductOverview extends React.Component {
  constructor(props) {
    super(props);
    this.initialize = this.initialize.bind(this);
    this.getImages = this.getImages.bind(this);

    this.state = {
      product: {
        id: "",
        name: "",
        title: "",
        mainImage: "",
        images: [],
        description: "",
        collectionName: "",
        tags: ["tag1", "tag2"],
        link: "",
      },
      patterns: [],
      fabrics: [],
    };
  }
  componentDidMount() {
    this.initialize();
  }

  initialize() {
    let productId = this.props.match.params.id;
    let product = CollectionStore.products.find((p) => p.id == productId);

    this.setState({ product: product });
  }

  getImages() {
    let allImages = [];

    if (this.state.product.id) {
      const req = require.context("../../images/products/", true, /.jpg$|.jpeg$/);
      let mainImage = req("./" + this.state.product.id + "/" + this.state.product.mainImage);
      allImages.push(
        <Paper key="main">
          <FullScreenImage
            image={mainImage}
            zoom={true}
          ></FullScreenImage>
        </Paper>
      );

      let images = this.state.product.images
        .filter((imageId) => imageId != this.state.product.mainImage)
        .sort()
        .map((i) => {
          let image = req("./" + this.state.product.id + "/" + i);
          return (
            <Paper key={i}>
              <FullScreenImage image={image} zoom={true}></FullScreenImage>
            </Paper>
          );
        });
      allImages = allImages.concat(images);
    }

    return allImages;
  }

  getTags() {
    let tags;
    if (this.state.product.tags) {
      tags = this.state.product.tags.join(", ");
      return <span>{tags}</span>;
    }

    return tags;
  }

  render() {
    return (
      <div className="product-overview page-container">
        <Grid container direction="row" justify="space-between">
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="space-between"
              className="product-overview__product-container"
            >
              <Grid item xs={12} sm={6}>
                <div className="product-overview__carousel">
                  <Carousel>{this.getImages()}</Carousel>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="product-overview__product-details">
                  <div className="product-overview__product-header flex flex-column mb-2">
                    <h1 className="flex">
                      {this.state.product.name && this.state.product.name}
                      {this.state.product.name && this.state.product.title && (
                        <span> - </span>
                      )}
                      {this.state.product.title && this.state.product.title}
                    </h1>
                    <h3 className="flex">
                      {this.state.product.collectionName}
                    </h3>
                  </div>
                  {this.state.product.description && (
                    <div className="product-overview__product-description flex mb-2">
                      <p> {this.state.product.description}</p>
                    </div>
                  )}
                  {this.state.product.tags && (
                    <div className="product-overview__product-tags flex mb-2">
                      <p>Tags:&nbsp;</p>
                      {this.getTags()}
                    </div>
                  )}
                  {this.state.product.link && (
                    <div>
                      <Divider />
                      <div className="product-overview__buy flex mt-2">
                        <Button variant="contained" color="primary">
                          Buy
                        </Button>
                      </div>
                    </div>
                  )}

                  {/* <div>
                    <p>fabric</p>
                    <p>patterns</p>
                  </div> */}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
