import React from "react";
import ZoomableImage from "../zoomableImage/zoomableImage";
import "./product.css";
import Slide from "@material-ui/core/Slide";

export default class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShownQuickView: false,
    };
    this.OnHoverImage = this.OnHoverImage.bind(this);
  }
  OnHoverImage(isHovered) {
    this.setState({ isShownQuickView: isHovered });
  }

  render() {
    return (
      <div className="product">
        <div className="product-image">
          <ZoomableImage
            image={this.props.image}
            link={this.props.link}
            onHoverEvent={this.OnHoverImage}
            title={this.props.title}
          />
          <Slide
            direction="up"
            in={this.state.isShownQuickView}
            timeout={{ appear: 300, enter: 300, exit: 300 }}
          >
            <div className="quick-view-container">
              <a
                href={this.props.link}
                className="quick-view-link"
                onMouseEnter={() => this.setState({ isShownQuickView: true })}
                onMouseLeave={() => this.setState({ isShownQuickView: false })}
              >
                <span className="quick-view-title">View</span>
              </a>
            </div>
          </Slide>
        </div>

        <a
          href="#"
          className="product-link"
          onMouseEnter={() => this.setState({ isShownQuickView: true })}
          onMouseLeave={() => this.setState({ isShownQuickView: false })}
        >
          <div className="product-summary">
            <p className="product-info">
              <span className="product-title">{this.props.title}</span>
              {this.props.showPrice && (
                <p className="product-price">
                  <span>{`${this.props.currency}${this.props.price} `}</span>
                </p>
              )}
            </p>
          </div>
        </a>
      </div>
    );
  }
}
