import React from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";

export default class VerticalImageBox extends React.Component {
  render() {
    return (
      <Grid item xs={12} md={4} direction="column">
        <Grid className="custom-grid-container" container direction="row">
          <Grid item xs={12} md={6}>
            {this.props.getBlogImageComponent()}
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container className="custom-grid-container">
              <Grid item xs={12} md={12}>
                {this.props.getBlogImageComponent()}
              </Grid>
              <Grid item xs={12} md={12}>
                {this.props.getBlogImageComponent()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

VerticalImageBox.propTypes = {
  getBlogImageComponent: PropTypes.func.isRequired,
};
