import React from "react";
import InlineHeader from "../inlineHeader/inlineHeader.jsx";
import "./navbar.css";
import Grid from "@material-ui/core/Grid";
import Facebook from "@material-ui/icons/Facebook";
import Twitter from "@material-ui/icons/Twitter";
import Instagram from "@material-ui/icons/Instagram";
import Youtube from "@material-ui/icons/YouTube";
import LinkedIn from "@material-ui/icons/LinkedIn";
import Sticky from "react-stickynode";
import Drawer from "@material-ui/core/Drawer";
import MenuIcon from "@material-ui/icons/Menu";
import configurations from "../../configurations/configurations.json";
import { isMobile } from "react-device-detect";
import * as logo from "../../images/emine-tarin-header-logo.png";

export default class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      drawerOpened: false,
    };
    this.toggleDrawer = this.toggleDrawer.bind(this);
  }

  isMobile() {
    return isMobile;
    // return true;
  }

  toggleDrawer(open) {
    this.setState({ drawerOpened: open });
  }

  getMobileNavBar() {
    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={2}>
          <a
            className="mobile-navigation-drawer-button"
            onClick={() => {
              this.toggleDrawer(true);
            }}
          >
            <MenuIcon fontSize="large" />
          </a>
          <div className="navbar-menu">
            <Drawer
              anchor="left"
              open={this.state.drawerOpened}
              onClose={() => {
                this.toggleDrawer(false);
              }}
            >
              <ul className="navbar-drawer-menu">
                <li className="navbar-menu-item">
                  <a className="navbar-item-url" href="/collections/1">
                    COLLECTIONS
                  </a>
                </li>
                <li className="navbar-menu-item">
                  <a className="navbar-item-url" href="/store">
                    STORE
                  </a>
                </li>
                <li className="navbar-menu-item">
                  <a className="navbar-item-url" href="/services">
                    SERVICES
                  </a>
                </li>
                <li className="navbar-menu-item">
                  <a className="navbar-item-url" href="/blog">
                    BLOG
                  </a>
                </li>
                <li className="navbar-menu-item">
                  <a className="navbar-item-url" href="/about">
                    ABOUT US
                  </a>
                </li>
              </ul>
            </Drawer>
          </div>
        </Grid>
        <Grid item xs={10}>
          <div className="logo-container">
            <a className="logo-url" href="/">
              <img src={logo} alt="EMINE TARIN" className="logo" />
            </a>
          </div>
        </Grid>
      </Grid>
    );
  }

  render() {
    return (
      <div className="navbar-container">
        <Grid container spacing={1}>
          <Grid item xs>
            <InlineHeader />
          </Grid>
        </Grid>

        <Sticky bottomBoundary="#content">
          <Grid container spacing={1}>
            <Grid item xs>
              <div id="header" className="navbar">
                {this.isMobile() && this.getMobileNavBar()}

                {!this.isMobile() && (
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={3}
                  >
                    <Grid item xs={false} sm={3}>
                      <div className="navbar-menu">
                        <ul>
                          <li className="navbar-menu-item">
                            <a
                              className="navbar-item-url"
                              href="/collections/1"
                            >
                              COLLECTIONS
                            </a>
                          </li>
                          <li className="navbar-menu-item">
                            <a className="navbar-item-url" href="/store">
                              STORE
                            </a>
                          </li>
                          <li className="navbar-menu-item">
                            <a className="navbar-item-url" href="/services">
                              SERVICES
                            </a>
                          </li>
                          <li className="navbar-menu-item">
                            <a className="navbar-item-url" href="/blog">
                              BLOG
                            </a>
                          </li>
                          <li className="navbar-menu-item">
                            <a className="navbar-item-url" href="/about">
                              ABOUT US
                            </a>
                          </li>
                        </ul>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="logo-container">
                        <a className="logo-url" href="/">
                          <img src={logo} alt="EMINE TARIN" className="logo" />
                        </a>
                      </div>
                    </Grid>
                    <Grid item xs={false} sm={3}>
                      <div className="navbar-social-media">
                        <a
                          href={configurations.socialMedia.instagram.url}
                          className="navbar-social-media-url"
                        >
                          <Instagram />
                        </a>
                        <a
                          href={configurations.socialMedia.facebook.url}
                          className="navbar-social-media-url"
                        >
                          <Facebook />
                        </a>
                        <a
                          href={configurations.socialMedia.youtube.url}
                          className="navbar-social-media-url"
                        >
                          <Youtube />
                        </a>

                        <a
                          href={configurations.socialMedia.linkedin.url}
                          className="navbar-social-media-url"
                        >
                          <LinkedIn />
                        </a>
                      </div>
                    </Grid>
                  </Grid>
                )}
              </div>
            </Grid>
          </Grid>
        </Sticky>
      </div>
    );
  }
}
