import React from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";

export default class ImageBox extends React.Component {
  render() {
    return (
      <Grid item xs={12} md={4} direction="column">
        <Grid container className="custom-grid-container">
          <Grid item xs={12} md={6}>
            {this.props.getBlogImageComponent()}
          </Grid>
          <Grid item xs={12} md={6}>
            {this.props.getBlogImageComponent()}
          </Grid>
          <Grid item xs={12} md={6}>
            {this.props.getBlogImageComponent()}
          </Grid>
          <Grid item xs={12} md={6}>
            {this.props.getBlogImageComponent()}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
ImageBox.propTypes = {
  getBlogImageComponent: PropTypes.func.isRequired,
};
