import React from "react";
import Grid from "@material-ui/core/Grid";
import Product from "../../components/product/product";
import FullScreenImage from "../../components/fullscreenImage/fullscreenImage";
import PageCard from "../../components/pageCard/pageCard";
import CategoryCard from "../../components/categoryCard/categoryCard";
import LockOutlined from "@material-ui/icons/LockOutlined";
import Cached from "@material-ui/icons/Cached";
import Call from "@material-ui/icons/Call";
import {
  colorEnum,
  layoutEnum,
} from "../../components/fullscreenImage/fullscreenHeaderEnums";
import "./mainpage.css";

// import * as women1 from "../../images/Lace-parasol-409794-min.jpg";
// import * as women2 from "../../images/Rococo-style-portrait-345273-min.jpg";
import * as mainpageCrousel1 from "../../images/main.jpg";
import * as mainpageCrousel2 from "../../images/main2.jpg";
import * as store from "../../images/Fashion-store-587411-min.jpg";
import * as blog from "../../images/Finding-profitable-offers-online-552598-min.jpg";
import * as sewingMachine from "../../images/services/ilya-lix-4l5fsRjJqLM-unsplash.jpg";
export default class MainPage extends React.Component {
  constructor(props) {
    super(props);
    this.featuredProducts = [
      {
        title: "The Knight Bride",
        image: "1.jpg",
        price: "180",
        currency: "£",
        link: "/products/5"
      },
      {
        title: "Tulip",
        image: "2.jpg",
        price: "180",
        currency: "£",
        link: "/products/8"
      },
      {
        title: "Fashion Women",
        image: "3.jpg",
        price: "180",
        currency: "£",
        link: "/products/14"
      },
      {
        title: "Party Dress",
        image: "4.jpg",
        price: "180",
        currency: "£",
        link: "/products/15"
      },
    ];
  }

  getFeaturedProducts() {
    let products;

    products = this.featuredProducts.map((p, index) => {
      return (
        <Grid key={index} item xs={12} md={3}>
          <Product
            link={p.link}
            image={p.image}
            title={p.title}
            price={p.price}
            currency={p.currency}
            showPrice={false}
          />
        </Grid>
      );
    });

    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={4}
      >
        {products}
      </Grid>
    );
  }

  render() {
    return (
      <div className="mainpage">
        <div className="mainpage-container">
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            {/* MainPage Carousel 1 */}
            <Grid item xs={12}>
              <div className="mainpage-carousel">
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12} sm={12}>
                    <FullScreenImage
                      headerPosition={layoutEnum.RIGHT_MIDDLE}
                      image={mainpageCrousel1}
                      mainColor={colorEnum.white}
                      hoverColor={colorEnum.black}
                    >
                      <h1>Introducing The New Collection</h1>
                      <a href="/store">
                        <span>SHOP NOW</span>
                      </a>
                    </FullScreenImage>
                  </Grid>
                </Grid>
              </div>
            </Grid>

            {/* featured products */}
            <Grid item xs={12}>
              <div className="page-content-container  featured-products">
                <h1 className="featured-products-header">Featured Products</h1>
                {this.getFeaturedProducts()}
              </div>
            </Grid>

            {/* Story and blog */}
            <Grid item xs={12}>
              <div className="page-content-container">
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid item xs={12} sm={6}>
                    <PageCard title="Our Story" url="/about" image={blog} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <PageCard title="Blog" url="/blog" image={store} />
                  </Grid>
                </Grid>
              </div>
            </Grid>

            {/* MainPage Carousel 2 */}
            <Grid item xs={12}>
              <div className="page-content-container">
                <FullScreenImage
                  headerPosition={layoutEnum.RIGHT_MIDDLE}
                  image={mainpageCrousel2}
                  mainColor={colorEnum.white}
                  hoverColor={colorEnum.black}
                >
                  <h1>Wear your dreams</h1>
                  <a href="/store">
                    <span>SHOP NOW</span>
                  </a>
                </FullScreenImage>
              </div>
            </Grid>

            {/* Categories */}
            <Grid item xs={12}>
              <div className="page-content-container">
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid item xs={12} sm={4}>
                    <CategoryCard
                      url="/collections/1?type=1"
                      title="Wedding Dresses"
                      image={blog}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CategoryCard
                      url="/collections/1?type=2"
                      title="Costumes"
                      image={blog}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CategoryCard
                      url="/collections/1?type=3"
                      title="Party Dresses"
                      image={blog}
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
        {/* Cusomer Card*/}
        <div className="customer-card-container">
          <Grid container direction="row" justify="space-between" spacing={4}>
            <Grid item xs={12} sm={4}>
              <div className="customer-card">
                <Cached fontSize="large" />
                <h1 className="customer-card-header">
                  Free Shipping and Returns
                </h1>
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <div className="customer-card">
                <LockOutlined fontSize="large" />
                <h1 className="customer-card-header">Secure Payments</h1>
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <div className="customer-card">
                <Call fontSize="large" />
                <h1 className="customer-card-header">Customer Service</h1>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
