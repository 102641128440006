import React from "react";
import PageBase from "../pageBase/pageBase";
import { Grid } from "@material-ui/core";
import configurations from "../../configurations/configurations.json";
import "./contact.css";

export default class Contact extends PageBase {
  getPageTitle() {
    return "Contact";
  }

  renderPageContent() {
    return (
      <div>
        <p>Hey, Don't be shy! Contact Us</p>
        <br />
        <p>
          We would love to listen your opinions just mail us whatever your
          think...
        </p>
        <br />

        <Grid container>
          <Grid item xs={12} md={6}>
            <a
              className="store-link"
              href={configurations.socialMedia.etsy.url}
            >
              Store
            </a>
          </Grid>
          <Grid item xs={12} md={6}>
            <p>
              <a className="store-link" href="mailto:info@eminetarin.co.uk">
                info@eminetarin.co.uk
              </a>
            </p>
          </Grid>
        </Grid>
        <br />
        <div className="contact-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d295.59009241983347!2d-0.2072549493184182!3d51.41209540847894!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487608be5739350d%3A0x7bbc762e3f6276b0!2s190%20Kingston%20Rd%2C%20London%20SW19%203NU%2C%20Birle%C5%9Fik%20Krall%C4%B1k!5e0!3m2!1str!2str!4v1620078433520!5m2!1str!2str"
            width="100%"
            height="300"
            style={{ border: 0 }}
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
        </div>
      </div>
    );
  }
}
