import React from "react";
import PropTypes from "prop-types";
import "./blogImage.css";

export default class BlogImage extends React.Component {
  constructor(props) {
    super(props);

    this.setTitle = this.setTitle.bind(this);
    this.fetchInstagramData = this.fetchInstagramData.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.setImageUrl = this.setImageUrl.bind(this);

    this.state = {
      title: "",
      imageUrl: "",
      hide: true
    };
  }

  setTitle(data) {
    this.setState({
      title: data.title,
    });
  }

  generateEmbeddedPostUrl() {
    return `https://graph.facebook.com/v10.0/instagram_oembed?&access_token=2386849338283376|acbf578ea4ed550314ba937cbf695d5b&url=${this.generatePostUrl()}`;
  }

  generatePostUrl() {
    let postUrl = `https://www.instagram.com/p/${this.props.postCode}`;

    return postUrl;
  }

  setImageUrl(data) {
    this.setState({
      imageUrl: data.thumbnail_url,
    });
  }

  fetchInstagramData() {
    let instagramPostUrl = this.generateEmbeddedPostUrl();

    fetch(instagramPostUrl)
      .then((response) => {

        if (!response.ok) {
          this.setState({
            hide: true
          });
          return;
        }
        return response.json()
      })
      .then((data) => {
        this.setTitle(data);
        this.setImageUrl(data);

        this.setState({
          hide: false
        });
      })
      .catch(() => {

      });;
  }

  componentDidMount() {
    this.fetchInstagramData();
  }

  render() {
    return (
      <div className="instagram-blog-image" style={{ display: this.state.hide ? 'none' : 'flex' }}>
        <div className="instagram-image-container">
          <a className="instagram-image-url" href={this.generatePostUrl()}>
            <div className="instagram-image-overlay image-radius"></div>

            <img
              className="instagram-image image-radius"
              src={this.state.imageUrl}
              alt="instagram"
            />
            <div className="instagram-image-header">{this.state.title}</div>
          </a>
        </div>
      </div>
    );
  }
}

BlogImage.propTypes = {
  postCode: PropTypes.string.isRequired,
  nextImageFunc: PropTypes.func.isRequired,
};
