import React from "react";
import "./fullscreenImage.css";
import { layoutEnum } from "./fullscreenHeaderEnums";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";
import Grid from "@material-ui/core/Grid";
// const sampleImage = require("../../images/Blonde-in-luxury-interior-343621-min.jpg");

export default class FullScreenImage extends React.Component {

  constructor(props) {
    super(props);
    this.baseHeaderStyle = "image-header-container";
    this.toggleImage = this.toggleImage.bind(this);
    this.state = {
      isSelected: false
    }
    this.zoomElement = null;

    this.setZoomElementRef = element => {
      this.zoomElement = element;
    };
  }

  isMobile() {
    return isMobile;
  }

  getInformationStyle() {
    let headerStyle = this.baseHeaderStyle;
    if (this.isMobile()) {
      headerStyle += " mobile";
      return headerStyle;
    }

    switch (this.props.headerPosition) {
      case layoutEnum.RIGHT_TOP:
        headerStyle += " right-top";
        break;
      case layoutEnum.RIGHT_MIDDLE:
        headerStyle += " right-middle";
        break;
      case layoutEnum.RIGHT_BOTTOM:
        headerStyle += " right-bottom";
        break;
      case layoutEnum.CENTER_TOP:
        headerStyle += " center-top";
        break;
      case layoutEnum.CENTER_MIDDLE:
        headerStyle += " center-middle";
        break;
      case layoutEnum.CENTER_BOTTOM:
        headerStyle += " center-bottom";
        break;
      case layoutEnum.LEFT_TOP:
        headerStyle += " left-top";
        break;
      case layoutEnum.LEFT_MIDDLE:
        headerStyle += " left-middle";
        break;
      case layoutEnum.LEFT_BOTTOM:
        headerStyle += " left-bottom";
        break;
      default:
        headerStyle += " center-middle";
        break;
    }

    return headerStyle;
  }
  toggleImage(){
    this.setState({
      isSelected: !this.state.isSelected
    });
  }

  componentDidUpdate(){
    console.log(this.zoomElement && this.state.isSelected);
    if(this.zoomElement && this.state.isSelected){
      this.zoomElement.scrollTo(window.innerWidth/8,window.innerHeight/9)
    }
  }

  render() {
    let image = this.props.image
      ? this.props.image
      : require("../../images/Blonde-in-luxury-interior-343621-min.jpg");

    return (
      <div className="fullscreen-image">
        <div className="fullscreen-image-container">
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12}>
              <div ref={this.setZoomElementRef}className={`${this.state.isSelected && this.props.zoom ? "fullscreen-image__zoom" : ""}`} onClick={(event) => this.toggleImage(true, event)}>
                <img className="img-responsive" src={image} alt="" />
              </div>

              {!this.isMobile() && this.props.children && (
                <div className={this.getInformationStyle()}>
                  {this.props.children}
                </div>
              )}
            </Grid>

            {this.isMobile() && this.props.children && (
              <Grid item xs={12}>
                <div className="mobile-header-container">
                  <div className={this.getInformationStyle()}>
                    {this.props.children}
                  </div>
                </div>
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    );
  }
}

FullScreenImage.propTypes = {
  headerPosition: PropTypes.number,
  image: PropTypes.string,
  children: PropTypes.element,
  mainColor: PropTypes.number,
  hoverColor: PropTypes.number,
};
