const layoutEnum = {
  RIGHT_TOP: 1,
  RIGHT_MIDDLE: 2,
  RIGHT_BOTTOM: 3,
  LEFT_TOP: 4,
  LEFT_MIDDLE: 5,
  LEFT_BOTTOM: 6,
  CENTER_TOP: 7,
  CENTER_MIDDLE: 8,
  CENTER_BOTTOM: 9,
};
const colorEnum = {
  white: 1,
  black: 2,
};

module.exports = {
  layoutEnum,
  colorEnum,
};
