import React from "react";
import Grid from "@material-ui/core/Grid";
import "./instagramBlog.css";
import data from "../../other/data.json";
import BlogImage from "../blogImage/blogImage";
import Shuffle from "shuffle-array";
import getRandomNumber from "../../utils/randomNumber";
import VerticalImageBox from "./VerticalImageBox/verticalImageBox";
import FullscreenImageBox from "./fullscreenImageBox/fullscreenImageBox";
import ImageBox from "./ImageBox/imageBox";

export default class InstagramBlog extends React.Component {
  constructor(props) {
    super(props);
    this.nextIndex = 0;
    this.initialize = this.initialize.bind(this);
    this.getBlogImageComponent = this.getBlogImageComponent.bind(this);
    this.instagramPostCodes = [];

    var mainElement = [];
    mainElement.push(<Grid item>{this.getMainRow()}</Grid>);
    mainElement.push(<Grid item>{this.generateRow()}</Grid>);

    this.state = {
      elements: mainElement,
      loading: false,
      prevY: 0,
    };
  }

  UNSAFE_componentWillMount() {
    this.initialize();
  }

  componentDidMount() {
    var options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };

    this.observer = new IntersectionObserver(
      this.handleObserver.bind(this),
      options
    );
    this.observer.observe(this.loadingRef);
  }

  handleObserver(entities) {
    const y = entities[0].boundingClientRect.y;
    if (this.state.prevY > y) {
      if (this.nextIndex < this.instagramPostCodes.length) {
        this.setState({ loading: true });
        let newElements = this.state.elements;

        newElements.push(<Grid item>{this.generateRow()}</Grid>);
        newElements.push(<Grid item>{this.generateRow()}</Grid>);
        newElements.push(<Grid item>{this.generateRow()}</Grid>);

        console.log(newElements);
        this.setState({
          elements: newElements,
        });
        this.setState({ loading: false });
      }
    }
    this.setState({ prevY: y });
  }

  initialize() {
    this.instagramPostCodes = [...data];
    console.log("instagramPostCodes: ", this.instagramPostCodes);

    this.shuffledImageCollection = Shuffle(this.instagramPostCodes);
    console.log("this.shuffledImageCollection: ", this.shuffledImageCollection);
  }

  getBlogImageComponent() {
    let element;
    if (this.shuffledImageCollection.length > this.nextIndex) {
      element = (
        <div className="image-column">
          <BlogImage postCode={this.shuffledImageCollection[this.nextIndex]} />
        </div>
      );
      this.nextIndex += 1;
    }
    return element;
  }

  getBoxWithImage() {
    return <ImageBox getBlogImageComponent={this.getBlogImageComponent} />;
  }

  getBoxWithFullImage() {
    return (
      <FullscreenImageBox getBlogImageComponent={this.getBlogImageComponent} />
    );
  }

  getBoxWithVerticalImage() {
    return (
      <VerticalImageBox getBlogImageComponent={this.getBlogImageComponent} />
    );
  }

  getRandomBox() {
    var min = 1;
    var max = 4;
    var rand = parseInt(getRandomNumber(min, max));

    if (rand % 3 === 0) return this.getBoxWithFullImage();
    else if (rand % 2 === 0) return this.getBoxWithImage();
    else return this.getBoxWithVerticalImage();
  }

  generateRow() {
    return (
      <Grid container>
        {this.getRandomBox()}
        {this.getRandomBox()}
        {this.getRandomBox()}
      </Grid>
    );
  }

  getMainRow() {
    return (
      <Grid container>
        {this.getBoxWithFullImage()}
        {this.getBoxWithImage()}
        {this.getBoxWithVerticalImage()}
      </Grid>
    );
  }

  render() {
    this.loadingCSS = {
      height: "100px",
      margin: "30px",
    };

    // To change the loading icon behavior
    this.loadingTextCSS = { display: this.state.loading ? "block" : "none" };

    var blogElements = this.state.elements.map((e) => e);
    return (
      <div className="instagram-blog-container">
        <Grid container>{blogElements}</Grid>
        <div
          ref={(loadingRef) => (this.loadingRef = loadingRef)}
          style={this.loadingCSS}
        >
          <span style={this.loadingTextCSS}>Loading...</span>
        </div>
      </div>
    );
  }
}
