import React from "react";
import Grid from "@material-ui/core/Grid";
import Facebook from "@material-ui/icons/Facebook";
import Twitter from "@material-ui/icons/Twitter";
import Instagram from "@material-ui/icons/Instagram";
import Youtube from "@material-ui/icons/YouTube";
import LinkedIn from "@material-ui/icons/LinkedIn";
import configurations from "../../configurations/configurations.json";

import "./footer.css";
import * as tr from "../../images/flags/tr.png";
import * as uk from "../../images/flags/uk.png";

export default class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.iconFontSize = { fontSize: 18 };
  }

  render() {
    return (
      <div>
        <footer>
          <div className="footer-container">
            <Grid container direction="row" justify="space-between" spacing={4}>
              <Grid item xs={12} md={3} justify="center">
                <div className="footer-column-container">
                  <div className="footer-column-container-wrapper">
                    <h3 className="footer-column-title">CUSTOMER CARE</h3>
                    <ul className="footer-item-list">
                      <li className="footer-list-item">
                        <a className="footer-item-url" href="/contact">
                          CONTACT
                        </a>
                      </li>

                      <li className="footer-list-item">
                        <a className="footer-item-url" href="/payment-options">
                          PAYMENT OPTIONS
                        </a>
                      </li>
                      <li className="footer-list-item">
                        <a
                          className="footer-item-url"
                          href="/shipping-delivery"
                        >
                          SHIPPING & DELIVERY
                        </a>
                      </li>
                      <li className="footer-list-item">
                        <a className="footer-item-url" href="/return-policy">
                          RETURNS POLICY
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={3}>
                <div className="footer-column-container">
                  <div className="footer-column-container-wrapper">
                    <h3 className="footer-column-title">COMPANY</h3>
                    <ul className="footer-item-list">
                      <li className="footer-list-item">
                        <a className="footer-item-url" href="/about">
                          ABOUT US
                        </a>
                      </li>
                      <li className="footer-list-item">
                        <a className="footer-item-url" href="/store">
                          STORE
                        </a>
                      </li>
                      <li className="footer-list-item">
                        <a className="footer-item-url" href="/blog">
                          BLOG
                        </a>
                      </li>

                      <li className="footer-list-item">
                        <a className="footer-item-url" href="/privacy-policy">
                          PRIVACY POLICY
                        </a>
                      </li>
                      <li className="footer-list-item">
                        <a className="footer-item-url" href="/terms-conditions">
                          TERM & CONDITIONS
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={3}>
                <div className="footer-column-container">
                  <div className="footer-column-container-wrapper">
                    <h3 className="footer-column-title">FOLLOW US</h3>
                    <ul className="footer-item-list">
                      <li className="footer-list-item">
                        <a
                          href={configurations.socialMedia.facebook.url}
                          className="footer-social-media-url"
                        >
                          <Facebook style={this.iconFontSize} />
                          <span className="icon-title">FACEBOOK</span>
                        </a>
                      </li>
                      <li className="footer-list-item">
                        <a
                          href={configurations.socialMedia.instagram.url}
                          className="footer-social-media-url"
                        >
                          <Instagram style={this.iconFontSize} />
                          <span className="icon-title">INSTAGRAM</span>
                        </a>
                      </li>
                      <li className="footer-list-item">
                        <a
                          href={configurations.socialMedia.youtube.url}
                          className="footer-social-media-url"
                        >
                          <Youtube style={this.iconFontSize} />
                          <span className="icon-title">YOUTUBE</span>
                        </a>
                      </li>
                      <li className="footer-list-item">
                        <a
                          href={configurations.socialMedia.linkedin.url}
                          className="footer-social-media-url"
                        >
                          <LinkedIn style={this.iconFontSize} />
                          <span className="icon-title">LINKEDIN</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={3}>
                <div className="footer-column-container">
                  <div className="footer-column-container-wrapper">
                    <div className="footer-contact">
                      <a
                        className="footer-contact-mail"
                        href="mailto:info@eminetarin.co.uk"
                      >
                        info@eminetarin.co.uk
                      </a>
                      {/* <a
                        className="footer-contact-tel"
                        href="tel:+61 2 9191 0245"
                      >
                        +61 2 9191 0245
                      </a> */}
                      <p className="footer-contact-info">
                        <span>We’re located in London, United Kingdom</span>
                        <span>Customer Care hours:</span>
                        <span>Monday - Friday | 9am to 4.30pm GMT </span>
                        <div className="flag-container">
                          <img src={uk} alt="United Kingdom" />
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="copy-right-container">
            <p className="copy-right-text">
              © 2020 EMINE TARIN, Inc. All rights reserved.
            </p>
          </div>
        </footer>
      </div>
    );
  }
}
