import React from "react";
import collectionStorage from "../../../collections.json";
import FullScreenImage from "../../../components/fullscreenImage/fullscreenImage";
import ProductCard from "../collection-card/collectionCard";
import FabricCard from "../collection-fabric-card/collectionFabricCard";
import Grid from "@material-ui/core/Grid";
import "./collection.css";
import qs from "qs";

export default class Collection extends React.Component {
  constructor(props) {
    super(props);
    this.getImages = this.getImages.bind(this);
    this.getFabrics = this.getFabrics.bind(this);

    this.state = {
      collection: {
        products: [1, 2],
      },
      products: [],
      fabrics: [],
    };
  }
  componentDidMount() {
    this.initialize();
  }

  initialize() {
    // TODO add filter for getting wedding dress or party dress
    if (this.props.location.search) {
      this.typeId = qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true,
      }).typeId;
    }

    let collectionId = this.props.match.params.id;
    let collection = collectionStorage.collections.find(
      (c) => c.id == collectionId
    );

    this.setState({
      collection: collection,
    });
  }
  getFabrics() {
    const mostUsedFabrics = this.state.collection.mostUsedFabrics;
    if (!mostUsedFabrics) return;
    const fabrics = collectionStorage.fabrics.filter(function (f) {
      return mostUsedFabrics.some(function (fId) {
        return f.id == fId;
      });
    });

    let fabricHtml = fabrics.map((p, index) => {
      return (
        <Grid key={index} item xs={12} md={3}>
          <FabricCard
            key={p.id}
            id={p.id}
            name={p.name}
            image={p.mainImage}
            title={p.title}
          />
        </Grid>
      );
    });

    return (
      <div className="collection__fabrics">
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={4}
        >
          {fabricHtml}
        </Grid>
      </div>
    );
  }
  getImages(skip, take) {
    if (take <= 0) return null;
    const skipCount = skip ? skip : 0;
    const takeCount = take ? take : 10;

    const productIds = this.state.collection.products.slice(
      skipCount,
      skipCount + takeCount
    );

    const products = collectionStorage.products.filter(function (p) {
      return productIds.some(function (pId) {
        return p.id == pId;
      });
    });

    let productHtml = products.map((p, index) => {
      return (
        <Grid key={index} item xs={12} md={3} >
          <ProductCard
            key={p.id}
            id={p.id}
            name={p.name}
            image={p.mainImage}
            title={p.title}
          />
        </Grid>
      );
    });

    return (
      <div className="collection__short-card-container">
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={4}
        >
          {productHtml}
        </Grid>
      </div>
    );
  }

  render() {
    let mainImage = "";
    if (this.state.collection.mainImage) {
      mainImage = require("../../../images/" + this.state.collection.mainImage);
    }

    return (
      <div className="page-container flex flex-column">
        <FullScreenImage image={mainImage} />
        <h1 className="collection__header">{this.state.collection.title}</h1>
        {this.state.collection.description && (
          <p className="collection__description">
            {this.state.collection.description}
          </p>
        )}
        {this.getImages(0, 8)}
        {this.state.collection.shortDescription1 && (
          <p className="collection__short-description">
            {this.state.collection.shortDescription1}
          </p>
        )}
        {this.getImages(8, 8)}
        {this.state.collection.shortDescription2 && (
          <p className="collection__short-description">
            {this.state.collection.shortDescription2}
          </p>
        )}
        {this.getImages(
          16,
          this.state.collection.products.length -
            (this.state.collection.products.length % 4) -
            16
        )}
        {this.state.collection.shortDescription3 && (
          <p className="collection__short-description">
            {this.state.collection.shortDescription1}
          </p>
        )}
        {this.state.collection.mostUsedFabrics &&
          this.state.collection.mostUsedFabrics.length > 0 && (
            <div>
              <h3 className="collection__header">Most Used Fabrics</h3>
              {this.getFabrics()}
            </div>
          )}
      </div>
    );
  }
}
