import React from "react";
import PageBase from "../pageBase/pageBase";

import "./shippingDelivery.css";

export default class ShippingDelivery extends PageBase {
  getPageTitle() {
    return "Shipping Delivery";
  }

  renderPageContent() {
    return (
      <div className="shipping-container">
        <section>
          Emine Tarin Limited ("we" and "us") is the operator of
          (https://www.eminetarin.co.uk) ("Website"). By placing an order
          through this Website you will be agreeing to the terms below. These
          are provided to ensure both parties are aware of and agree upon this
          arrangement to mutually protect and set expectations on our service.
        </section>
        <p></p>
        <section className="shipping-policy">
          <h4>1. General</h4>
          <p>
            Subject to stock availability. We try to maintain accurate stock
            counts on our website but from time-to-time there may be a stock
            discrepancy and we will not be able to fulfill all your items at
            time of purchase. In this instance, we will fulfill the available
            products to you, and contact you about whether you would prefer to
            await restocking of the backordered item or if you would prefer for
            us to process a refund.
          </p>
        </section>
        <section className="shipping-policy">
          <h4>2. Shipping Costs</h4>
          <p>
            Shipping costs are calculated during checkout based on weight,
            dimensions and destination of the items in the order. Payment for
            shipping will be collected with the purchase. This price will be the
            final price for shipping cost to the customer.
          </p>
        </section>
        <section className="shipping-policy">
          <h4>3. Returns</h4>
          <h5>3.1 Return Due To Change Of Mind</h5>
          <p>
            Emine Tarin Limited will happily accept returns due to change of
            mind as long as a request to return is received by us within 7 days
            of receipt of item and are returned to us in original packaging,
            unused and in resellable condition. Return shipping will be paid at
            the customers expense and will be required to arrange their own
            shipping. Once returns are received and accepted, refunds will be
            processed to store credit for a future purchase. We will notify you
            once this has been completed through email. (Emine Tarin Limited)
            will refund the value of the goods returned but will NOT refund the
            value of any shipping paid.
          </p>
        </section>

        <section className="shipping-policy">
          <h5>3.2 Warranty Returns</h5>
          <p>
            Emine Tarin Limited will happily honor any valid warranty claims,
            provided a claim is submitted within 90 days of receipt of items.
            Customers will be required to pre-pay the return shipping, however
            we will reimburse you upon successful warranty claim. Upon return
            receipt of items for warranty claim, you can expect Emine Tarin
            Limited to process your warranty claim within 7 days. Once warranty
            claim is confirmed, you will receive the choice of: (a) refund to
            your payment method (b) a refund in store credit (c) a replacement
            item sent to you (if stock is available)
          </p>
        </section>
        <section className="shipping-policy">
          <h4>4. Delivery Terms</h4>
          <h5> 4.1 Transit Time Domestically</h5>
          <p>In general, domestic shipments are in transit for 2 - 7 days</p>
        </section>
        <section className="shipping-policy">
          <h5>4.2 Transit time Internationally</h5>
          <p>
            Generally, orders shipped internationally are in transit for 4 - 22
            days. This varies greatly depending on the courier you have
            selected. We are able to offer a more specific estimate when you are
            choosing your courier at checkout.
          </p>
        </section>
        <section className="shipping-policy">
          <h5>4.3 Dispatch Time</h5>
          <p>
            Orders are usually dispatched within 2 business days of payment of
            order Our warehouse operates on Monday - Friday during standard
            business hours, except on national holidays at which time the
            warehouse will be closed. In these instances, we take steps to
            ensure shipment delays will be kept to a minimum.
          </p>
        </section>

        <section className="shipping-policy">
          <h5>4.4 Change Of Delivery Address</h5>
          <p>
            For change of delivery address requests, we are able to change the
            address at any time before the order has been dispatched.
          </p>
        </section>

        <section className="shipping-policy">
          <h5>4.5 P.O. Box Shipping</h5>
          <p>
            Emine Tarin Limited will ship to P.O. box addresses using postal
            services only. We are unable to offer couriers services to these
            locations.
          </p>
        </section>

        <section className="shipping-policy">
          <h5>4.6 Military Address Shipping</h5>
          <p>
            We are able to ship to military addresses using HM Forces Mail
            (Royal Mail). We are unable to offer this service using courier
            services.
          </p>
        </section>

        <section className="shipping-policy">
          <h5>4.7 Items Out Of Stock</h5>
          <p>
            If an item is out of stock, we will wait for the item to be
            available before dispatching your order. Existing items in the order
            will be reserved while we await this item.
          </p>
        </section>

        <section className="shipping-policy">
          <h5>4.8 Delivery Time Exceeded</h5>
          <p>
            If delivery time has exceeded the forecasted time, please contact us
            so that we can conduct an investigation.
          </p>
        </section>

        <section className="shipping-policy">
          <h4>5. Tracking Notifications</h4>
          <p>
            Upon dispatch, customers will receive a tracking link from which
            they will be able to follow the progress of their shipment based on
            the latest updates made available by the shipping provider if
            shipping has tracking options.
          </p>
        </section>
        <section className="shipping-policy">
          <h4>6. Parcels Damaged In Transit</h4>
          <p>
            If you find a parcel is damaged in-transit, if possible, please
            reject the parcel from the courier and get in touch with our
            customer service. If the parcel has been delivered without you being
            present, please contact customer service with next steps.
          </p>
        </section>

        <section className="shipping-policy">
          <h4>7. Duties & Taxes</h4>
          <h5>7.1 Sales Tax</h5>
          <p>
            Sales tax has already been applied to the price of the goods as
            displayed on the website
          </p>
        </section>

        <section className="shipping-policy">
          <h5>7.2 Import Duties & Taxes</h5>
          <p>
            Import duties and taxes for international shipments may be liable to
            be paid upon arrival in destination country. This varies by country,
            and Emine Tarin Limited encourage you to be aware of these potential
            costs before placing an order with us. If you refuse to to pay
            duties and taxes upon arrival at your destination country, the goods
            will be returned to Emine Tarin Limited at the customers expense,
            and the customer will receive a refund for the value of goods paid,
            minus the cost of the return shipping. The cost of the initial
            shipping will not be refunded.
          </p>
        </section>

        <section className="shipping-policy">
          <h4>8. Cancellations</h4>
          <p>
            If you change your mind before you have received your order, we are
            able to accept cancellations at any time before the order has been
            dispatched. If an order has already been dispatched, please refer to
            our refund policy.
          </p>
        </section>

        <section className="shipping-policy">
          <h4>9. Insurance</h4>
          <p>
            Parcels are insured for loss and damage up to the value as stated by
            the courier.
          </p>
        </section>

        <section className="shipping-policy">
          <h5>9.1 Process for parcel damaged in-transit</h5>
          <p>
            We will process a refund or replacement as soon as the courier has
            completed their investigation into the claim.
          </p>
        </section>

        <section className="shipping-policy">
          <h5>9.2 Process for parcel lost in-transit</h5>
          <p>
            We will process a refund or replacement as soon as the courier has
            conducted an investigation and deemed the parcel lost.
          </p>
        </section>

        <section className="shipping-policy">
          <h4>10. Customer service</h4>
          <p>
            For all customer service enquiries, please email us at{" "}
            <a href="mailto:info@eminetarin.co.uk">info@eminetarin.co.uk</a>
          </p>
        </section>
      </div>
    );
  }
}
