import React from "react";
import Grid from "@material-ui/core/Grid";
import "./pageBase.css";

export default class PageBase extends React.Component {
  getPageTitle() {
    return "Unknown Page";
  }

  renderPageContent() {
    return <div></div>;
  }

  render() {
    return (
      <div className="page-container">
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs={false} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <div className="page-container-wrapper">
              <h1 className="page-header">{this.getPageTitle()}</h1>
              <div className="page-content">{this.renderPageContent()}</div>
            </div>
          </Grid>
          <Grid item xs={false} md={3}></Grid>
        </Grid>
      </div>
    );
  }
}
