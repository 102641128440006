import React from "react";
import PageBase from "../pageBase/pageBase";

import "./returnPolicy.css";

export default class ReturnPolicy extends PageBase {
  getPageTitle() {
    return "Return and Refund Policy";
  }

  renderPageContent() {
    return (
      <div className="return-policy-container">
        <section className="return-policy">
          <h4>Last updated: May 03, 2021</h4>
          <p>
            Thank you for shopping at Emine Tarin Limited. If, for any reason,
            You are not completely satisfied with a purchase We invite You to
            review our policy on refunds and returns. The following terms are
            applicable for any products that You purchased with Us.
          </p>
        </section>

        <section className="return-policy">
          <h4>Interpretation and Definitions</h4>
          <h5>Interpretation</h5>
          <p>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
        </section>

        <section className="return-policy">
          <h5>Definitions</h5>
          <p>For the purposes of this Return and Refund Policy:</p>
          <ul>
            <li>
              <strong>Company </strong>(referred to as either "the Company",
              "We", "Us" or "Our" in this Agreement) refers to Emine Tarin
              Limited, 190 Kingston Road SW19 3NU.
            </li>
            <li>
              <strong>Goods </strong>refer to the items offered for sale on the
              Service.
            </li>
            <li>
              <strong>Orders </strong>mean a request by You to purchase Goods
              from Us.
            </li>
            <li>
              <strong>Service </strong>refers to the Website.
            </li>
            <li>
              <strong>Website </strong>refers to Emine Tarin Limited, accessible
              from https://www.eminetarin.co.uk
            </li>
            <li>
              <strong>You </strong>means the individual accessing or using the
              Service, or the company, or other legal entity on behalf of which
              such individual is accessing or using the Service, as applicable.
            </li>
          </ul>
        </section>
        <section className="return-policy">
          <h4>Your Order Cancellation Rights</h4>
          <p>
            You are entitled to cancel Your Order within 7 days without giving
            any reason for doing so. The deadline for cancelling an Order is 7
            days from the date on which You received the Goods or on which a
            third party you have appointed, who is not the carrier, takes
            possession of the product delivered. In order to exercise Your right
            of cancellation, You must inform Us of your decision by means of a
            clear statement. You can inform us of your decision by:
          </p>
          <ul>
            <li>
              By email:{" "}
              <a href="mailto:info@eminetarin.co.uk">info@eminetarin.co.uk</a>
            </li>
          </ul>
          <p>
            We will reimburse You no later than 14 days from the day on which We
            receive the returned Goods. We will use the same means of payment as
            You used for the Order, and You will not incur any fees for such
            reimbursement.
          </p>
        </section>

        <section className="return-policy">
          <h4>Conditions for Returns</h4>
          <p>
            In order for the Goods to be eligible for a return, please make sure
            that:
          </p>
          <ul>
            <li>The Goods were purchased in the last 7 days</li>
            <li>The Goods are in the original packaging</li>
          </ul>
          <p>The following Goods cannot be returned:</p>
          <ul>
            <li>
              The supply of Goods made to Your specifications or clearly
              personalized.
            </li>
            <li>
              The supply of Goods which according to their nature are not
              suitable to be returned, deteriorate rapidly or where the date of
              expiry is over.
            </li>
            <li>
              The supply of Goods which are not suitable for return due to
              health protection or hygiene reasons and were unsealed after
              delivery.
            </li>
            <li>
              The supply of Goods which are, after delivery, according to their
              nature, inseparably mixed with other items.
            </li>
          </ul>
          <p>
            We reserve the right to refuse returns of any merchandise that does
            not meet the above return conditions in our sole discretion. Only
            regular priced Goods may be refunded. Unfortunately, Goods on sale
            cannot be refunded. This exclusion may not apply to You if it is not
            permitted by applicable law.
          </p>
        </section>

        <section className="return-policy">
          <h4>Returning Goods</h4>
          <p>
            You are responsible for the cost and risk of returning the Goods to
            Us. You should send the goods at the following address:
          </p>
          <p className="post-code">
            190 Kingston Road <br />
            Merton
            <br />
            London
            <br />
            SW19 3NU
            <br />
            United Kingdom
          </p>
          <p>
            We cannot be held responsible for Goods damaged or lost in return
            shipment. Therefore, We recommend an insured and trackable mail
            service. We are unable to issue a refund without actual receipt of
            the Goods or proof of received return delivery.
          </p>
        </section>

        <section className="return-policy">
          <h4>Gifts</h4>
          <p>
            If the Goods were marked as a gift when purchased and then shipped
            directly to you, You'll receive a gift credit for the value of your
            return. Once the returned product is received, a gift certificate
            will be mailed to You. If the Goods weren't marked as a gift when
            purchased, or the gift giver had the Order shipped to themselves to
            give it to You later, We will send the refund to the gift giver.
          </p>
        </section>

        <section className="return-policy">
          <h4>Contact Us</h4>
          <p>
            If you have any questions about our Returns and Refunds Policy,
            please contact us:
          </p>
          <ul>
            <li>
              By email:
              <a href="mailto:info@eminetarin.co.uk">info@eminetarin.co.uk</a>
            </li>
          </ul>
        </section>
      </div>
    );
  }
}
