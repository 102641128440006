import React from "react";
import PropTypes from "prop-types";
import "./categoryCard.css";

export default class CategoryCard extends React.Component {
  render() {
    return (
      <div className="category-card-container">
        <div>
          <a href={this.props.url || "#"} className="category-card-url">
            <div className="category-card-wrapper">
              <img
                className="category-card-image img-responsive"
                src={this.props.image}
                alt={this.props.title}
              />
              <div className="category-card-overlay"></div>
              <section className="category-card-section">
                <div className="category-card-header">
                  <h1>{this.props.title}</h1>
                </div>
                {this.props.description && (
                  <p className="category-card-description">{this.props.description}</p>
                )}
              </section>
            </div>
          </a>
        </div>
      </div>
    );
  }
}

CategoryCard.propTypes = {
  url: PropTypes.string,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string
};
