import React from "react";
import "./inlineHeader.css";
export default class InlineHeader extends React.Component {
  render() {
    return (
      <div className="inline-header">
        <p className="inline-header-title">
          <span>FREE SHIPPING UNITED KINGDOM</span>
        </p>
      </div>
    );
  }
}
