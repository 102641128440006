import React from "react";
import "../collection-card/collectionCard.css";

export default class CollectionFabricCard extends React.Component {
  render() {
    let mainImage = "";
    console.log(this.props.image);
    if (this.props.image) {
      mainImage = require("../../../images/products/" +
        this.props.id +
        "/" +
        this.props.image);
    }

    return (
      <div className="">
        <a href={"/product/" + this.props.id}>
          <div className="collection-card__wrapper">
            <img className="img-responsive" src={mainImage} />
            <div className="collection-card__overlay">
              <h5 className="collection-card__title">
                {this.props.name + " - " + this.props.title}
              </h5>
            </div>
          </div>
        </a>
      </div>
    );
  }
}
