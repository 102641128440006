import React from "react";
import "./collectionCard.css";

export default class CollectionCard extends React.Component {
  render() {
    let mainImage = "";
    console.log(this.props.image);
    if (this.props.image) {
      mainImage = require("../../../images/products/" +
        this.props.id +
        "/" +
        this.props.image);
    }

    return (
      <div className="collection-card">
        <a href={"/products/" + this.props.id}>
          <div className="collection-card__wrapper">
            <img className="img-responsive" src={mainImage} />
            {this.props.name && (
              <div className="collection-card__overlay">
                <h5 className="collection-card__title">{this.props.name}</h5>
              </div>
            )}
          </div>
        </a>
      </div>
    );
  }
}
